import { Preview } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ButtonLink,
  IContract,
  formatDollarAmount,
  formatEnergyAmount,
  getFormattedEnergyEffectiveRate,
  useStatementDataMetrics,
} from "@synota-io/synota-shared-ui";
import { useRef } from "react";
import { FEATURE_ENABLE_AR_EXPOSURE } from "../../../utils/environment";
import { generatePath } from "react-router-dom";
import { CONTRACT_EXPOSURE_PATH } from "../../../paths";

interface Props {
  contract?: IContract | null;
}

export const SupplierMetricsTable = ({ contract }: Props) => {
  const { ytd, priorMonth, mtd, isLoading, hasData } = useStatementDataMetrics({ contract });

  const boxRef = useRef<HTMLDivElement>(null);

  return (
    <Stack gap={6} ref={boxRef}>
      <Slide in direction="up">
        <Card>
          <CardContent sx={{ overflow: { lg: "visible" } }}>
            {isLoading && !hasData ? null : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell variant="head">Energy Delivered (MWh)</TableCell>
                      <TableCell variant="head">Total Amount Invoiced (USD)</TableCell>
                      <TableCell variant="head">Effective Rate ($/MWh)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ytd.statement ? (
                      <TableRow>
                        <TableCell variant="head">YTD</TableCell>
                        <TableCell>{formatEnergyAmount(ytd.statement.unitsDeliveredMwh)}</TableCell>
                        <TableCell>{formatDollarAmount(ytd.statement.amountDue)}</TableCell>
                        <TableCell>
                          {getFormattedEnergyEffectiveRate(
                            ytd.statement.amountDue,
                            ytd.statement.unitsDeliveredMwh,
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {priorMonth.statement ? (
                      <TableRow>
                        <TableCell variant="head">Prior Month</TableCell>
                        <TableCell>
                          {formatEnergyAmount(priorMonth.statement.unitsDeliveredMwh)}
                        </TableCell>
                        <TableCell>{formatDollarAmount(priorMonth.statement.amountDue)}</TableCell>
                        <TableCell>
                          {getFormattedEnergyEffectiveRate(
                            priorMonth.statement.amountDue,
                            priorMonth.statement.unitsDeliveredMwh,
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {mtd.statement ? (
                      <TableRow>
                        <TableCell variant="head">MTD</TableCell>
                        <TableCell>{formatEnergyAmount(mtd.statement.unitsDeliveredMwh)}</TableCell>
                        <TableCell>{formatDollarAmount(mtd.statement.amountDue)}</TableCell>
                        <TableCell>
                          {getFormattedEnergyEffectiveRate(
                            mtd.statement.amountDue,
                            mtd.statement.unitsDeliveredMwh,
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={3}>
                        <Stack direction="row" spacing={2}>
                          <span>A/R:</span>
                          <span>
                            {formatDollarAmount(mtd.statement?.currentAmountOutstanding || 0)}
                          </span>
                          <Box flexGrow={1} />
                          {FEATURE_ENABLE_AR_EXPOSURE && contract ? (
                            <ButtonLink
                              to={generatePath(CONTRACT_EXPOSURE_PATH, {
                                contractId: contract.uuid,
                              })}
                              startIcon={<Preview />}
                              variant="text"
                              size="small"
                            >
                              Review Exposure
                            </ButtonLink>
                          ) : null}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Slide>
    </Stack>
  );
};
