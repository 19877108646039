import { Stack } from "@mui/material";
import { Form, ToggleField, ButtonMenu, useAccount } from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import { PROVIDER_AVATAR_MAP } from "../components/ProviderAvatar";
import { useEffect, useRef } from "react";
import { Link } from "@mui/icons-material";

interface Props {
  onSubmit: (data: { vendor: string }) => void;
}

const ALLOWED_PROVIDERS = ["foreman", "other"];

export const SelectProviderForm = ({ onSubmit }: Props) => {
  const { isAdmin } = useAccount();
  const { control, handleSubmit, watch } = useForm({
    mode: "all",
    values: { vendor: { value: "" } },
  });

  const formRef = useRef<HTMLFormElement>(null);

  const onFormSubmit = handleSubmit(({ vendor: { value } }) => {
    onSubmit({ vendor: value });
  });

  const values = watch();

  useEffect(() => {
    onFormSubmit();
  }, [onFormSubmit, values.vendor]);

  return (
    <ButtonMenu
      color="primary"
      variant="contained"
      fullWidth
      disabled={!isAdmin}
      sx={{ whiteSpace: "nowrap" }}
      startIcon={<Link />}
      menu={() => {
        return (
          <Stack padding={4} minWidth="16em">
            <Form ref={formRef} onSubmit={onFormSubmit}>
              <ToggleField
                control={control}
                orientation="vertical"
                exclusive
                fullWidth
                name="vendor"
                options={ALLOWED_PROVIDERS.map((provider) => ({
                  value: provider,
                  render: PROVIDER_AVATAR_MAP[provider],
                }))}
              />
            </Form>
          </Stack>
        );
      }}
    >
      Link Account
    </ButtonMenu>
  );
};
