import {
  useTheme,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";
import { MinersInfo } from "../components/MinersInfo";
import { MinersStatus } from "../components/MinersStatus";
import { SelectProviderForm } from "../forms/SelectProviderForm";
import {
  FlexLoadingBlock,
  IContract,
  useAccount,
  useContracts,
  useForemanAccess,
  useMinerManagement,
  useToast,
  BadgeTypography,
  ConfirmationModal,
  useConfirmationModal,
} from "@synota-io/synota-shared-ui";
import { ForemanAvatar, PROVIDER_AVATAR_MAP } from "../components/ProviderAvatar";
import { useState } from "react";
import { ManagementSetupForm } from "../forms/ManagementSetupForm";
import { MinerManagementError } from "./MinerManagementError";
import { LinkOff } from "@mui/icons-material";

interface Props {
  contract: IContract;
}

export const MinerManagementSummary = ({ contract }: Props) => {
  const { foremanAccess, consumerApprovedSharing } = contract.minerManagement;

  const { jwt, isAdmin, isSupplier } = useAccount();
  const {
    disconnectAccess,
    toggleConsumerApprovedSharing,
    isLoading: isLoadingForemanAccess,
  } = useForemanAccess();
  const { refetch: refetchContracts } = useContracts();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const toast = useToast();
  const minerManagement = useMinerManagement(contract.uuid, { enabled: foremanAccess });

  const confirmationModal = useConfirmationModal();

  const [vendor, setVendor] = useState<string | null>(null);

  if (foremanAccess) {
    if (minerManagement.isLoading) {
      return <FlexLoadingBlock />;
    }

    const mainActionButton =
      isSupplier || !isAdmin ? null : (
        <Button
          size="small"
          color="error"
          startIcon={<LinkOff />}
          onClick={() => {
            confirmationModal.onOpen({
              onConfirm: () => {
                disconnectAccess(
                  { jwt, contract_shared_uuid: contract.uuid },
                  {
                    onSuccess() {
                      confirmationModal.onClose();
                      setVendor(null);
                      refetchContracts();
                      toast.info("Successfully removed Foreman access from this contract");
                    },
                  },
                );
              },
              isLoading: isLoadingForemanAccess,
              title: "Do you want to Unlink your Foreman account from this contract?",
            });
          }}
        >
          Unlink Account
        </Button>
      );

    return (
      <Stack width="100%" height={{ sm: "100%", lg: "auto", xl: "100%" }}>
        <StyledStack>
          <Stack gap={4} height="100%" direction={{ xs: "row", sm: "column" }}>
            <ForemanAvatar>
              {minerManagement.minerManagementInfo ? (
                <>Client ID: {minerManagement.minerManagementInfo?.client_id}</>
              ) : null}
            </ForemanAvatar>
            {minerManagement.minerManagementInfo ? (
              <>
                {isSupplier ? (
                  <BadgeTypography pl={3} status="success" sx={{ width: "10em" }}>
                    <strong>Linked</strong>
                  </BadgeTypography>
                ) : (
                  <>
                    {isAdmin ? (
                      <Tooltip
                        title={
                          consumerApprovedSharing
                            ? "Counterparty can view this data in their dashboard"
                            : "Enabling allows your counterparty to view this data in their dashboard"
                        }
                      >
                        <FormControlLabel
                          sx={{ width: "11em", pl: 3 }}
                          label={consumerApprovedSharing ? "Viewing Active" : "Enable Viewing"}
                          control={
                            <Switch
                              disabled={isLoadingForemanAccess}
                              checked={consumerApprovedSharing}
                              onChange={(_, checked) => {
                                toggleConsumerApprovedSharing(
                                  {
                                    contract_shared_uuid: contract.uuid,
                                    jwt,
                                  },
                                  {
                                    onSuccess() {
                                      if (checked) {
                                        toast.success(
                                          "Authorized counterparty to use Foreman information",
                                        );
                                      } else {
                                        toast.info(
                                          "Removed Foreman permissions from the counterparty",
                                        );
                                      }
                                      refetchContracts();
                                    },
                                  },
                                );
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
            {isXs || isLg ? null : mainActionButton}
          </Stack>
          {minerManagement.error ? (
            <MinerManagementError management={minerManagement} />
          ) : (
            <>
              <MinersInfo management={minerManagement} flexGrow={1} />
              {isXs || isLg ? null : <MinersStatus management={minerManagement} />}
            </>
          )}
        </StyledStack>
        {isXs || isLg ? (
          <Stack
            flexWrap="wrap"
            pt={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowGap={4}
          >
            <MinersStatus direction="row" management={minerManagement} />
            {mainActionButton}
          </Stack>
        ) : null}
        {confirmationModal.open ? <ConfirmationModal {...confirmationModal} /> : null}
      </Stack>
    );
  }

  const ProviderAvatar = vendor ? PROVIDER_AVATAR_MAP[vendor] : () => null;

  return (
    <StyledStack flexDirection={{ xs: "column-reverse", md: "column" }}>
      <Stack
        width={{ xs: "100%", sm: "50%", md: "25%", lg: "35%", xl: "25%" }}
        height="100%"
        gap={6}
        justifyContent={vendor ? "start" : "center"}
      >
        <ProviderAvatar />
        {vendor ? (
          <Stack flexGrow={1} justifyContent="end">
            <Button
              disabled={!isAdmin}
              color="error"
              fullWidth
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => {
                setVendor(null);
              }}
            >
              Cancel
            </Button>
          </Stack>
        ) : (
          <SelectProviderForm onSubmit={({ vendor }) => setVendor(vendor)} />
        )}
      </Stack>
      <Stack width="75%" alignItems="center" justifyContent="center" height="100%">
        <ManagementSetupForm contract={contract} vendor={vendor} />
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "start",
  },
  alignItems: "center",
  justifyContent: "start",
  gap: theme.spacing(4),
  width: "100%",
  height: "100%",
}));
